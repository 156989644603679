<template>
  <div class="main_panel">
    <ConfirmLogout ref="logout"></ConfirmLogout>
    <div class="panel_nav">
      <network-menu class="net_menu"></network-menu>
      <button @click="logout" class="buttona">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          stroke-width="2px"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="sbui-icon"
          style="
            display: block;
            vertical-align: middle;
            height: 14px;
            margin-right: 4px;
          "
        >
          <path
            d="M19,10H11m5,3l2.913-2.913c.048-.048,.048-.126,0-.174l-2.913-2.913m-2-4v-.5c0-.828-.672-1.5-1.5-1.5H3C1.895,1,1,1.895,1,3v14c0,1.105,.895,2,2,2H12.5c.828,0,1.5-.672,1.5-1.5v-.5"
          ></path>
        </svg>
        <span class="truncate">Logout</span>
      </button>
    </div>
    <transition name="fade" mode="out-in">
      <transaction-history-panel
        class="panel_content"
      ></transaction-history-panel>
    </transition>
  </div>
</template>
<script>
import NetworkMenu from "../NetworkSettings/NetworkMenu";
import TransactionHistoryPanel from "./TransactionHistoryPanel";
import ConfirmLogout from "@/components/modals/ConfirmLogout.vue";

export default {
  components: {
    NetworkMenu,
    TransactionHistoryPanel,
    ConfirmLogout,
  },
  methods: {
    logout() {
      // this.$store.dispatch('logout');
      // @ts-ignore
      this.$refs.logout.open();
    },
  },
};
</script>
<style scoped lang="scss">
@use '../../main';

.main_panel {
  display: grid;
  grid-template-rows: max-content 1fr;
  row-gap: 6px;
}
.panel_nav {
  background-color: var(--bg-wallet-light);
  /*display: flex;*/
  /*align-items: center;*/
  /*flex-direction: row;*/
  display: flex;
  grid-template-columns: max-content max-content 1fr;
  /*justify-content: space-between;*/
  padding: 24px 16px;
  font-size: 14px;
  justify-content: space-between;

  > * {
    outline: none !important;
    padding: 0px 8px;
    border-radius: 4px;
  }
}

.hover_but {
  transition-duration: 0.2s;
  cursor: pointer;
  &:hover {
    box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.3);
  }
}

.panel_content {
  overflow: auto;
  background-color: var(--bg-wallet-light);
  height: 100%;
}

.buttona {
  box-shadow: rgba(0, 0, 0, 0.012) 0 0 0 0, rgba(0, 0, 0, 0.012) 0 0 0 0,
    rgba(0, 0, 0, 0.22) 0 1px 1px 0, #f93260da 0 0 0 1px,
    rgba(0, 0, 0, 0.012) 0 0 0 0, rgba(0, 0, 0, 0.012) 0 0 0 0,
    rgb(64 68 82/8%) 0 2px 5px 0;
  display: flex;
  border: none;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transition-duration: 0.2s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
  outline-width: 0;
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0/0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  font-size: 0.75rem;
  justify-content: center;
  line-height: 1rem;
  text-align: center;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  background-color: #f93260cb;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  border-radius: 0.25rem;
  align-items: center;
  cursor: pointer;
  position: relative;
  -webkit-appearance: button;
  background-image: none;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.012) 0 0 0 0, rgba(0, 0, 0, 0.012) 0 0 0 0,
      rgba(0, 0, 0, 0.22) 0 1px 1px 0, #3fcf8e 0 0 0 1px,
      rgba(0, 0, 0, 0.012) 0 0 0 0, rgba(0, 0, 0, 0.012) 0 0 0 0,
      rgb(64 68 82/8%) 0 2px 5px 0 !important;
    background-color: #33b57b;
  }
}

.logout {
  margin-left: auto;
  margin-left: auto;
  border-radius: 4px;
  background: #fff;
  padding: 0px 10px;
  color: #000 !important;
  font-weight: 500;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
}

@include main.medium-device {
  .panel_nav {
    padding: 12px 16px;
  }
}
</style>
