









































import "reflect-metadata";
import { Vue, Component, Prop } from "vue-property-decorator";

import moment from "moment";
// import TxHistoryValue from '@/components/SidePanels/TxHistoryValue.vue'
import TxHistoryNftFamilyGroup from "@/components/SidePanels/TxHistoryNftFamilyGroup.vue";
import {
  ITransactionData,
  TransactionType,
  UTXO,
} from "@/store/modules/history/types";
import { BIcon, BIconArrowUpRight, BIconArrowDown } from "bootstrap-vue";
import { AvaNetwork } from "@/js/AvaNetwork";
import { trimmedLocaleString } from "../../helpers/helper";
import { Buffer } from "avalanche";
import ImportExport from "@/components/SidePanels/History/ViewTypes/ImportExport.vue";
import BaseTx from "@/components/SidePanels/History/ViewTypes/BaseTx.vue";
import StakingTx from "@/components/SidePanels/History/ViewTypes/StakingTx.vue";
import getMemoFromByteString from "@/services/history/utils";

@Component({
  components: {
    TxHistoryNftFamilyGroup,
    BIcon,
    BIconArrowUpRight,
    BIconArrowDown,
  },
  filters: {
    trimmedLocaleString,
  },
})
export default class TxHistoryRow extends Vue {
  @Prop() transaction!: ITransactionData;

  get explorerUrl(): string | null {
    let network: AvaNetwork = this.$store.state.Network.selectedNetwork;
    if (network.explorerSiteUrl) {
      return `${network.explorerSiteUrl}/tx/${this.transaction.id}`;
    }
    return null;
  }

  get txid() {
    return this.transaction.id;
  }

  get hasMultisig() {
    return (
      this.transaction.outputs?.filter((utxo) => utxo.addresses.length > 1)
        .length > 0 || false
    );
  }

  get memo(): string | null {
    const memo = this.transaction.memo;
    return getMemoFromByteString(memo);
  }

  get date(): Date {
    return new Date(this.transaction.timestamp);
  }

  get time() {
    return moment(this.transaction.timestamp);
  }

  get timeText(): string {
    let now = Date.now();
    let diff = now - new Date(this.transaction.timestamp).getTime();

    let dayMs = 1000 * 60 * 60 * 24;

    if (diff > dayMs) {
      return this.time.format("MMM DD, YYYY");
    }
    return this.time.fromNow();
  }

  get viewComponent() {
    let type = this.transaction.type;

    switch (type) {
      case "export":
      case "import":
      case "pvm_export":
      case "pvm_import":
        return ImportExport;
      case "add_delegator":
      case "add_validator":
        return StakingTx;
      default:
        return BaseTx;
    }
  }
}
