




















export default {
  props: {
    label: {
      type: String,
      default: "Name",
    },
    error_value: {
      type: Boolean,
      default: false,
    },
    error_message: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      value: "",
    };
  },
  watch: {
    value: function (val: string | boolean) {
      //@ts-ignore
      this.$emit("change", { value: val, type: this.label.toLowerCase() });
    },
  },
};
