




























import "reflect-metadata";
import { Vue, Component, Prop } from "vue-property-decorator";

import Spinner from "@/components/misc/Spinner.vue";
import TxHistoryRow from "@/components/SidePanels/TxHistoryRow.vue";
import { ITransactionData } from "@/store/modules/history/types";
import { AvaNetwork } from "@/js/AvaNetwork";
import { ITransaction } from "@/components/wallet/transfer/types";

@Component({
  components: {
    TxHistoryRow,
    Spinner,
  },
})
export default class TransactionHistoryPanel extends Vue {
  get isExplorer(): boolean {
    let network: AvaNetwork | null = this.$store.state.Network.selectedNetwork;
    if (!network) return false;
    if (network.explorerUrl) {
      return true;
    }
    return false;
  }

  get isEmpty(): boolean {
    if (this.transactions.length === 0) {
      return true;
    }
    return false;
  }
  get isUpdating(): boolean {
    return this.$store.state.History.isUpdating;
  }
  get transactions(): ITransactionData[] {
    let res: ITransactionData[] = this.$store.state.History.transactions;

    if (!res) return [];

    let seenId: string[] = [];
    let r: ITransactionData[] = res.filter((tx) => {
      if (seenId.includes(tx.id)) {
        return false;
      }
      seenId.push(tx.id);
      return true;
    });
    // A simple filter to ignore duplicate transactions (can happen if you send to self)
    return r;
  }

  get isActivityPage() {
    if (this.$route.fullPath.includes("/activity")) {
      return true;
    }
    return false;
  }

  get explorerUrl(): string {
    let addr = this.$store.state.address.split("-")[1];
    return `https://explorer.djtx.network/address/${addr}`;
  }
}
